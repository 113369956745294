import React, {useEffect} from 'react';
import {View, Image} from 'react-native';
import theme from '@style';
import {goBack, goTo} from '@utils';
import PhoneInput from './components/phone-input';
import CodeInput from './components/code-input';
import {inputProps, styles} from './login.style';
import {CheckBox, Input} from '@rneui/themed';
import Text from '@basicComponents/text';
import AccountTip from './components/account-tip';
import Button from '@basicComponents/button';
import {userLogin} from './login.service';
import {postReport, TReportType, TSourceType} from '@/services/global.service';
import globalStore from '@/services/global.state';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {setScratchAuth} from '@/services/global.service';
import {useTranslation} from 'react-i18next';
import {defaultPhoneCode} from '@/config';
import {postUserInfo} from '@/services/global.service';
import Privacy from '@components/business/privacy/privacy';
import {setUniqueId} from '@/utils/moengage';
import AccountWrap from '@/components/business/account/account-wrap';
import {ReactComponent as CheckedIcon} from '@assets/icons/checked.svg';
import {useScreenSize} from '../hooks/size.hooks';
import DirectEntry from './components/direct-entry';
import {useRoute} from '@react-navigation/native';
// import useGoBack from '../hooks/useGoBack';
// import {homePage} from '@/config';
import {useThirdPartyAuth} from '../hooks/useThirdPartyAuth';
// import LazyImage from '@/components/basic/image';
// const icon = require('../../assets/icons/login/login-botttom.webp');

const SingUp = (props: NavigatorScreenProps) => {
  const {i18n} = useTranslation();

  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPage =
    ((props.route.params as BasicObject)?.sucessPage as string) || null;
  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPageParams =
    ((props.route.params as BasicObject)?.sucessPageParams as BasicObject) ||
    null;
  const route = useRoute();
  // const [layoutHeight, setLayoutHeight] = React.useState(0);

  const [userPhone, setUserPhone] = React.useState('');
  const [OTPCode, setOTPCode] = React.useState('');
  const [invitaCode, setInvitaCode] = React.useState(() => {
    if (globalStore.isWeb) {
      const code = localStorage.getItem('invitationCode');
      return code && code !== 'undefined' ? code : '';
    }
    return '';
  });
  // const goBackLink = useGoBack();
  // console.log('invitaCode', invitaCode);
  const [is18, setIs18] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [blured, setBlured] = React.useState(true);
  const [userPhoneCode, setUserPhoneCode] = React.useState(defaultPhoneCode);
  const {calcActualSize} = useScreenSize();
  const {handleThirdPartyAuth} = useThirdPartyAuth({
    sucessPage: sucessPage || undefined,
    sucessPageParams: sucessPageParams || undefined,
    userPhone,
    OTPCode,
  });
  useEffect(() => {
    // 根据url 参数判断，是否第三方登录返回数据
    // 拿到url参数 from 来源 platform state
    // 注册登录获取token
    // 路由变化之后，判断是否需要进行loginBySource
    // registerBySource();
    handleThirdPartyAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);
  // const registerBySource = async () => {
  //   globalStore.globalLoading.next(true);
  //   // Get URL parameters
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const source = urlParams.get('source');
  //   const state = urlParams.get('state');
  //   const isNewUser = urlParams.get('isNewUser');

  //   // Store third-party login params
  //   if (source && state) {
  //     // Store auth params
  //     localStorage.setItem('thirdPartySource', source);
  //     localStorage.setItem('thirdPartyState', state);
  //     globalStore.setItem('thirdPartySource', source);
  //     globalStore.setItem('thirdPartyState', state);
  //   }

  //   // If no source or state, return to normal login flow
  //   if (!source || !state) {
  //     globalStore.globalLoading.next(false);
  //     return;
  //   }

  //   try {
  //     // console.log('1111', isNewUser, typeof isNewUser);
  //     if (isNewUser === 'true') {
  //       //  这里还需要做一步处理，就是记录当前callback操作，如果登录成功了，则跳转到下一步路由
  //       globalStore.globalLoading.next(false);
  //       window.location.href =
  //         window.location.origin + '/bind-phone?source=login';
  //       return;
  //     }
  //     // 模拟登录
  //     const res = await getToken('', userPhone, OTPCode);
  //     globalStore.globalLoading.next(false);
  //     if (res?.token) {
  //       globalStore.token = res.token;
  //       // const userInfo = await postUserInfo();
  //       // globalStore.userInfo = userInfo;
  //       // goBackLink.goBack();
  //       if (sucessPage) {
  //         goTo(sucessPage, {sucessPageParams});
  //       } else {
  //         goTo(homePage, {screen: 'Home'});
  //       }
  //       // goTo('Home');
  //     }
  //   } catch (error) {
  //     globalStore.globalLoading.next(false);
  //     console.error('Login error:', error);
  //   }
  // };
  return (
    <div className="flex flex-col w-full h-[100vh] overflow-auto bg-white">
      <AccountWrap
        isClose={false}
        onBackClick={goBack}
        title={i18n.t('login.label.sign')}>
        <div className="flex flex-col gap-5 p-3">
          <PhoneInput
            userPhoneCode={userPhoneCode}
            setUserPhoneCode={setUserPhoneCode}
            userPhone={userPhone}
            setUserPhone={setUserPhone}
          />
          <CodeInput
            setValueOrCode={setOTPCode}
            switchIndex={1}
            userPhone={userPhone}
            OTPCode={OTPCode}
          />
          {/* <View style={styles.interval} /> */}
          <View
            style={[
              styles.inputBox,
              blured ? styles.greyBorder : styles.deepBorder,
            ]}>
            <Image
              style={theme.icon.s}
              source={require('@assets/icons/login/invitation-code.webp')}
            />
            <View style={theme.flex.flex1}>
              <Input
                {...inputProps}
                onFocus={() => setBlured(false)}
                onBlur={() => setBlured(true)}
                value={invitaCode}
                onChangeText={setInvitaCode}
                maxLength={8}
                placeholder={i18n.t('login.tip.referral-code')}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  fontWeight: '500',
                }}
              />
            </View>
          </View>
          {[
            {checked: is18, onPress: setIs18, label: 'login.tip.page18'},
            {checked: agree, onPress: setAgree, label: 'login.tip.notify'},
          ].map((v, i) => (
            <CheckBox
              key={i}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={{
                padding: theme.paddingSize.zorro,
                margin: 0,
                // marginTop: theme.paddingSize.m * 2,
                marginLeft: theme.paddingSize.zorro,
                marginRight: theme.paddingSize.zorro,
              }}
              checked={v.checked}
              onPress={() => v.onPress(!v.checked)}
              checkedIcon={<CheckedIcon className="text-c1 size-5 flex-none" />}
              uncheckedIcon={
                <Image
                  style={{
                    width: calcActualSize(20),
                    height: calcActualSize(20),
                  }}
                  source={require('@assets/icons/unchecked.webp')}
                />
              }
              title={
                <span
                  className={`pl-2 text-sm ${
                    i === 0 ? 'font-medium' : 'font-normal'
                  }`}>
                  {i18n.t(v.label)}
                </span>
              }
            />
          ))}
          <View
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              // marginVertical: theme.paddingSize.m * 2,
              marginVertical: 0,
            }}>
            <Button
              type="linear-primary"
              onPress={() => {
                globalStore.globalLoading.next(true);
                userLogin(userPhone, OTPCode, invitaCode, false)
                  .then(async res => {
                    globalStore.token = res.token;
                    const data: BasicObject = {fromLogin: true};
                    sucessPage && (data.sucessPage = sucessPage);
                    sucessPageParams &&
                      (data.sucessPageParams = sucessPageParams);
                    setScratchAuth();
                    goTo('SetPassword', data);
                    if (res.isNewUser) {
                      postReport({
                        reportType: TReportType.REGISTER,
                        inviteCode: invitaCode,
                        sourceType:
                          localStorage.getItem('from') === 'im'
                            ? TSourceType.IM
                            : TSourceType.CHANNEL,
                      }).then(_ => localStorage.removeItem('from'));
                    }
                    const userInfo = await postUserInfo();
                    globalStore.userInfo = userInfo;
                    const {userId, packageId} = userInfo;
                    setUniqueId(`${userId}_${packageId}`);
                  })
                  .finally(() => globalStore.globalLoading.next(false));
              }}
              disabled={
                userPhone.length !== 10 ||
                OTPCode.length !== 6 ||
                !is18 ||
                !agree
              }>
              <Text
                color={theme.basicColor.white}
                size="large"
                fontWeight="700">
                {i18n.t('login.label.next')}
              </Text>
            </Button>
          </View>
          <AccountTip
            tip="login.tip.has-account"
            linkTip="login.tip.sign-in"
            onPressLink={() => goTo('Login')}
          />
          {/* 新增三方登录入口 */}
          <DirectEntry />
          <View
            style={[
              {
                paddingHorizontal: theme.paddingSize.l,
              },
            ]}>
            <Privacy fix={true} />
          </View>
        </div>
      </AccountWrap>
    </div>
  );
};

export default SingUp;
