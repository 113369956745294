import globalStore from '@/services/global.state';
import {getAdjustAttribution} from '@/utils/adjust';
import {http, getLoginHttp, getAttribution} from '@utils';
import DeviceInfo from 'react-native-device-info';
import {packageId} from '@/config';

export const sendCode = (userPhone: string) => {
  return http.post('app/sendCode', {userPhone});
};

export const userLogin = async (
  userPhone: string,
  code: string,
  userInviteCode?: string,
  isLogin: boolean = true,
) => {
  const date: {[k: string]: any} = {
    userPhone,
    code,
    userInviteCode,
    ...getAttribution(userInviteCode),
  };

  if (!isLogin && globalStore.isWeb && !userInviteCode) {
    const itemInviteCode = localStorage.getItem('invitationCode');
    date.userInviteCode =
      itemInviteCode && itemInviteCode !== 'undefined'
        ? itemInviteCode
        : undefined;
  }

  if (globalStore.isWeb) {
    const attr = getAdjustAttribution();
    if (attr && attr.adid) {
      date.isReport = attr?.tracker_name !== 'Organic';
      date.adid = attr?.adid || '';
    }
    if (globalStore.promotionChannelId) {
      date.promotionChannelId = globalStore.promotionChannelId;
    }
    if (globalStore.promotionLinkCode) {
      date.promotionLinkCode = globalStore.promotionLinkCode;
    }
  }

  const deviceCode = globalStore.isWeb
    ? getAdjustAttribution()?.adid
    : await DeviceInfo.getAndroidId();

  return getLoginHttp({
    deviceCode,
  }).post<
    null,
    {
      token: string;
      isNewUser: boolean;
    }
  >('app/userLoginNew', date);
};

export const passwordLogin = (userPhone: string, password: string) => {
  return http.post<null, string>('app/password/login', {
    userPhone,
    password,
  });
};

export const updatePassword = (password: string, otpCode: string) => {
  return http.post<null, string>('app/user/set/password', {
    password,
    code: otpCode,
  });
};

interface renderAuthParams {
  source: string;
  // packageId: string;
  // channelId: string;
  // deviceCode: string;
  // versionCode: string;
  // adjustAdid: string;
  // url: string;
}

// 获取第三方登录跳转地址的接口
// https://api-testing.dream22.xyz/app/sdk/user/oauth/google/5?url=https://indusbet-test.pages.dev/login
// google 是参数，外部传递进来的，5 是包名称
export const renderAuth = async (data: renderAuthParams) => {
  // let linkUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  const baseUrl = `${window.location.origin}${window.location.pathname}`;

  // 需要保留的参数列表
  const paramsToKeep = [
    'fromPage',
    'backPage',
    'sucessPage',
    'sucessPageParams',
  ];

  // 创建新的 URLSearchParams 对象来存储需要保留的参数
  const newParams = new URLSearchParams();

  // 遍历需要保留的参数，如果存在则添加到新的参数对象中
  paramsToKeep.forEach(param => {
    const value = urlParams.get(param);
    if (value) {
      newParams.append(param, value);
    }
  });

  // 构建新的 URL
  const newLinkUrl = `${baseUrl}${
    newParams.toString() ? `?${newParams.toString()}` : ''
  }`;

  // console.log('newLinkUrl', linkUrl, newLinkUrl);

  const dataParms = {
    url: newLinkUrl,
    ...getAttribution(),
    ...data,
  };
  try {
    return http.post('app/sdk/user/oauth/renderAuth', dataParms);
  } catch (err) {
    console.log(err);
  }
};

export const getThirdLoginUrl = async (type: string) => {
  const linkUrl = encodeURIComponent(window.location.href);
  try {
    const response = await http.get(
      `app/sdk/user/oauth/${type}/${packageId}?url=${linkUrl}`,
    );
    return response;
  } catch (error) {
    console.error('Error getting third party login URL:', error);
    throw error;
  }
};

// 注册登录获取token
// source: 第三方平台
export const getToken = (source?: string, phone?: string, code?: string) => {
  // 这里source 从store里获取，state从store里获取
  const sourefrom =
    globalStore.getItem('thirdPartySource') ||
    localStorage.getItem('thirdPartySource') ||
    source;
  const state =
    globalStore.getItem('thirdPartyState') ||
    localStorage.getItem('thirdPartyState');
  // if (!sourefrom) {
  //   return Promise.reject('source is required');
  // }
  // if (!state) {
  //   return Promise.reject('state is required');
  // }
  // /app/sdk/user/oauth/{platform}/{packageId}:login
  // 如果绑定手机号登录则需要传染phone,code ,跳过绑定手机号则传入state就OK
  // phone 非必填 state 必填
  // code 验证码
  return http.post<
    null,
    {
      token: string;
      isNewUser: boolean;
    }
  >(`/app/sdk/user/oauth/${sourefrom}/${packageId}:login`, {
    state: state,
    phone: phone,
    code: code,
  });
};
