export interface ThirdPartyConfig {
  key: string;
  icon: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const thirdPartyConfig: ThirdPartyConfig[] = [
  {
    key: 'google',
    icon: require('@/assets/imgs/login/google.webp'),
    disabled: false,
  },
  {
    key: 'facebook',
    icon: require('@/assets/imgs/login/facebook.webp'),
    onClick: () => console.log('Facebook login'),
    disabled: true,
  },
  {
    key: 'whatsapp',
    icon: require('@/assets/imgs/login/whatsapp.webp'),
    onClick: () => console.log('WhatsApp login'),
    disabled: true,
  },
];
