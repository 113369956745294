import React from 'react';
import i18n from '@i18n';
import {thirdPartyConfig} from '../config/third-party';
import {Divider} from 'antd-mobile';
import {renderAuth} from '../login.service';

const DirectEntry = () => {
  const handleThirdLogin = async (type: string) => {
    try {
      if (!type) {
        console.error('Please select a Source');
        return;
      }
      const res = await renderAuth({
        source: type,
      });
      console.log(res);
      window.location.href = res as any; // 拿到第三方跳转地址
      // 余下的事情第三方内部做，完成登录等操作。
    } catch (error) {
      console.error('Third party login error:', error);
    }
  };

  return (
    <div className="flex flex-col items-center w-full gap-3">
      <div className="w-full">
        <Divider
          className="text-t2 text-[0.875rem] font-din font-bold"
          style={{
            margin: 0,
          }}>
          {i18n.t('login.direct_entry')}
        </Divider>
      </div>
      <div className="flex flex-row justify-center gap-6">
        {thirdPartyConfig.map(item => (
          // item.disabled 为true时，置灰，div不可以点击
          // 点击的时候，如果disabled为true，则不进行任何操作
          // 不可点击，鼠标样式为禁止     cursor: not-allowed;
          <div
            key={item.key}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              cursor: item.disabled ? 'not-allowed' : 'pointer',
              pointerEvents: item.disabled ? 'none' : 'auto',
              opacity: item.disabled ? 0.5 : 1,
            }}
            onClick={() => {
              if (item.disabled) {
                return;
              }
              handleThirdLogin(item.key);
            }}>
            <img
              src={item.icon}
              alt={`${item.key} login`}
              className="w-10 h-10"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DirectEntry;
